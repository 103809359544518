import Link from 'next/link';
import React from 'react';

import { useGetHomePageInfo } from 'Src/hooks/useGetHomePageInfo';
import { LL } from 'Src/i18n';

import Image404Asset from '../../404-image2x.png';

export const ConnectedErrorPage: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const homePageInfo = useGetHomePageInfo();
  return (
    <div className={'flex h-full flex-col items-center justify-center'}>
      <div className={'mb-10 h-[284px] w-[284px]'}>
        <img className={'w-[400px]'} src={Image404Asset} />
      </div>
      <div className={'mb-4 text-4xl font-bold uppercase text-gray-800'}>
        {LL.components.Connected404Page.title()}
      </div>
      <div className={'mb-20 text-center text-2xl leading-7 text-zinc-600'}>
        {LL.components.Connected404Page.description()}
      </div>
      <Link
        passHref
        href={homePageInfo}
        className={'text-2xl text-brand-blue-dark hover:underline'}
      >
        {LL.components.Connected404Page.home()}
      </Link>
    </div>
  );
};
