import React from 'react';

import logo from './centered-text-logo.png';

export const Connecting: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div
      className={'mb-10 grid h-full content-center justify-center text-center'}
    >
      <div className={'flex flex-col gap-2'}>
        <img src={logo} className={'h-20'} />
        <div>{'Connecting...'}</div>
      </div>
    </div>
  );
};
