// Test text to ISO Date
export const DATE_REGEX = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const RGB_REGEX = /^rgb(a)?\((\d{1,}),\s?(\d{1,}),\s?(\d{1,})(.*)?$/;
export const HEX_REGEX = /^#([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})$/;
export const TEN_DIGITS_2_PRECISIONS_REGEX =
  /^(\d{1,8}\.?\d?\d?|\d{9}\.?\d?|\d{10})$/;
export const NOTE_140_CHARACTERS_REGEX = /^.{1,140}$/;
export const ONE_DIGIT_AFTER_DECIMAL_POINT = /^\d+(\.\d{1})?$/;
export const NUMBER_ONLY = /^(0|[1-9]\d*)$/;
export const SPECIAL_CHARS = /[.*+?^${}()|[\]\\]/g;
export const ESCAPED_MATCH = /\$&/;
export const NUMBER_IN_BRACKET = /\((\d+)\)/;
export const CURRENCY_SYMBOLS = /[!$€£]/g;
export const PATH_NAME = /\?.*/;
